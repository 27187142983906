import {Logo} from '@job-ish/blocks/components';
import {Separator} from '@job-ish/ui/components';

export const FoundersNote = () => (
	<div className="mx-auto flex max-w-2xl flex-col px-8">
		<figure className="mt-4 flex flex-auto flex-col justify-between">
			<blockquote className="text-pretty text-lg leading-8">
				<p>
					“I was frustrated with traditional job boards that prioritize employers and ads over job seekers.
					Most platforms focus on serving companies, leaving job seekers as an afterthought. I wanted
					something different — a job board made for people like me, for those actively seeking opportunities,
					not for advertisers or recruiters. So, I created <span className="font-bold">job-ish</span> -{' '}
					<span className="decoration underline decoration-plum11 decoration-wavy underline-offset-4">
						the only job board built for job seekers
					</span>
					.”
				</p>
			</blockquote>
			<figcaption className="mt-4 flex items-center gap-x-6">
				<div className="mt-1 flex items-center gap-2 text-mauve11">
					<Logo />
					<Separator className="h-10" orientation="vertical" />
					Founder and fellow job seeker
				</div>
			</figcaption>
		</figure>
	</div>
);

'use client';

import {useState} from 'react';
import {CTA} from '~/shared/components/cta';
import {Pricing} from '@job-ish/blocks/components';
import {Badge, Button, IconButton, TextButton} from '@job-ish/ui/components';
import {IconArrowDown, IconArrowRight, IconFlame} from '@tabler/icons-react';
import {AnimatePresence, m} from 'framer-motion';
import NextLink from 'next/link';

import type {Price} from '@job-ish/database/types';
import {FAQ} from './faq';
import {Features} from './features';
import {FoundersNote} from './founders-note';
import {LogoCloud} from './logo-cloud';

type LandingProps = {
	prices: Price[];
};

export const Landing = ({prices}: LandingProps) => {
	const [billingInterval, setBillingInterval] = useState<'month' | 'year'>('month');

	return (
		<>
			<div className="container relative mx-auto flex min-h-dvh flex-col pt-24">
				<m.div
					animate={{y: 0, opacity: 1}}
					className="relative z-10 m-auto mx-auto flex w-fit flex-col items-center justify-center p-4 text-center"
					initial={{y: -10, opacity: 0}}
					transition={{duration: 0.25}}
				>
					<div className="z-10 flex grow flex-col gap-12">
						<div className="max-w-3xl text-center font-extrabold">
							<Badge
								as={NextLink}
								className="mx-auto mb-3 border-none px-1"
								color="primary"
								href="https://app.job-ish.com"
								interactive
								suffix={<IconArrowRight className="h-4 w-4" />}
							>
								<Badge size="sm" suffix={<IconFlame className="-mx-1 h-3 w-3" />}>
									New
								</Badge>
								Explore our job board
							</Badge>
							<AnimatePresence mode="sync">
								{['Skip', 'the', '-ish'].map((word, idx) => (
									<m.span
										animate={{opacity: 1, transition: {ease: 'easeOut', duration: 2, delay: 0.2 * idx}}}
										className="bg-gradient-to-br from-plum11 to-violet11 bg-clip-text pb-1 text-4xl font-semibold text-transparent sm:text-5xl"
										exit={{opacity: 0, transition: {ease: 'easeOut', duration: 0.4}}}
										initial={{opacity: 0}}
										key={`${word}-${idx}`}
									>
										{word}{' '}
									</m.span>
								))}
							</AnimatePresence>
							<div className="mt-2 text-2xl font-bold text-mauve11">Get in. Get hired. Get out.</div>
							<h1 className="decoration mt-2 text-lg font-bold text-mauve12 underline decoration-plum11 underline-offset-4">
								The only job board built for job seekers.
							</h1>
							<p className="mt-4 max-w-xl text-sm text-mauve11">
								<span className="font-bold">job-ish</span> brings together the best of job boards,
								productivity tools, and AI to help you land your dream job quickly and efficiently — without
								ads, spam, or distractions.
							</p>
						</div>
						<div className="flex flex-col gap-1">
							<Button
								as="a"
								className="mx-auto w-64"
								color="primary"
								href="https://app.job-ish.com"
								size="xl"
							>
								Find Jobs Now
							</Button>
							<div className="text-sm text-mauve11">
								or{' '}
								<TextButton
									as="a"
									className="inline-flex"
									color="primary"
									href="https://app.job-ish.com/auth/login"
									size="sm"
								>
									sign up for a premium account
								</TextButton>
							</div>
						</div>
					</div>
				</m.div>
				<IconButton
					aria-label="Scroll down to features"
					as="a"
					circular
					className="z-10 mx-auto mb-12 mt-auto"
					href="#features"
					icon={IconArrowDown}
					intent="ghost"
					size="xl"
				/>
			</div>
			<Features />
			<LogoCloud />
			<FoundersNote />
			<div
				className="relative mx-auto flex w-full max-w-full grow flex-col p-4 sm:max-w-4xl sm:grow-0"
				id="pricing"
			>
				<m.div
					className="mx-auto max-w-4xl px-4 pb-12 text-center text-3xl font-semibold text-mauve12 sm:text-4xl"
					variants={{visible: {opacity: 1, y: 0}, hidden: {opacity: 0, y: -15}}}
				>
					<h2>
						Simple,{' '}
						<span className="bg-gradient-to-br from-plum11 to-violet11 bg-clip-text text-transparent">
							transparent pricing
						</span>{' '}
						- no surprises
					</h2>
					<p className="pt-2 text-base text-mauve11">
						Securing a job is hard enough. We aren&apos;t here to make it harder.
					</p>
				</m.div>
				<Pricing billingInterval={billingInterval} prices={prices} setBillingInterval={setBillingInterval}>
					<Button
						as="a"
						className="mt-4"
						color="primary"
						href="https://app.job-ish.com/auth/login"
						rel="noopener noreferrer"
					>
						Get Started Now
					</Button>
				</Pricing>
			</div>
			<FAQ />
			<CTA />
		</>
	);
};
